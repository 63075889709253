import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/index',
  },
  {
    path: '/index',
    name: 'index',
    component: () => import('../views/index.vue')
  },
  {
    path: '/preface',
    name: 'preface',
    component: () => import('../views/preface/index.vue')
  },
  {
    path: '/history',
    name: 'history',
    component: () => import('../views/history/index.vue')
  },
  {
    path: '/warriors',
    name: 'warriors',
    component: () => import('../views/warriors/index.vue')
  },
  {
    path: '/ancient',
    name: 'ancient',
    component: () => import('../views/ancient/index.vue')
  },
  {
    path: '/culture',
    name: 'culture',
    component: () => import('../views/culture/index.vue')
  },
  {
    path: '/visit',
    name: 'visit',
    component: () => import('../views/visit/index.vue')
  },
  {
    path: '/creation',
    name: 'creation',
    component: () => import('../views/creation/index.vue')
  },
  {
    path: '/game',
    name: 'game',
    component: () => import('../views/game/game.vue')
  },
  // {
  //   path: '/game/happy',
  //   name: 'happy',
  //   component: () => import('../views/game/game.vue')
  // },
  {
    path: '/share/:id',
    name: 'share',
    component: () => import('../views/game/share.vue')
  }
]

const router = new VueRouter({
  routes
})
export default router
